import 'regenerator-runtime/runtime';
import 'swiper/swiper-bundle.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import '../css/load.scss';

// import Swiper from 'swiper/bundle';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const $loader = $('.app-loader');

	initHeaderMenuToggler();
	initSearchFromIcon();
	initPhotoSwipe('.accordion__image');
	initAccordion();
	initOffersSelector();
	movePaymentStepTitle();
	initSaleSubmit();
	initTimeCounter();
	initOffersAnalytics();

	function addLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.addClass('active');
	}

	function removeLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.removeClass('active');
	}

	function initHeaderMenuToggler() {
		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			e.preventDefault();
		});

		$('.btn-close').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
		});
	}

	function initSearchFromIcon() {
		$('.search__btn').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.search-form').toggleClass('active');
		});
	}

	function initPhotoSwipe(gallerySelector) {
		if (document.querySelectorAll(gallerySelector).length <= 0) {
			return;
		}

		var parseThumbnailElements = function (el) {
			var thumbElements = el.childNodes,
				numNodes = thumbElements.length,
				items = [],
				figureEl,
				linkEl,
				size,
				item;

			for (var i = 0; i < numNodes; i++) {
				figureEl = thumbElements[i]; // <figure> element

				// include only element nodes
				if (figureEl.nodeType !== 1) {
					continue;
				}

				linkEl = figureEl.children[0]; // <a> element

				size = linkEl.getAttribute('data-size').split('x');

				// create slide object
				item = {
					src: linkEl.getAttribute('href'),
					w: parseInt(size[0], 10),
					h: parseInt(size[1], 10)
				};

				if (figureEl.children.length > 1) {
					// <figcaption> content
					item.title = figureEl.children[1].innerHTML;
				}

				if (linkEl.children.length > 0) {
					// <img> thumbnail element, retrieving thumbnail url
					item.msrc = linkEl.children[0].getAttribute('src');
				}

				item.el = figureEl; // save link to element for getThumbBoundsFn
				items.push(item);
			}

			return items;
		};

		// find nearest parent element
		var closest = function closest(el, fn) {
			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		// triggers when user clicks on thumbnail
		var onThumbnailsClick = function (e) {
			e = e || window.event;
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			var eTarget = e.target || e.srcElement;

			// find root element of slide
			var clickedListItem = closest(eTarget, function (el) {
				return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
			});

			if (!clickedListItem) {
				return;
			}

			// find index of clicked item by looping through all child nodes
			// alternatively, you may define index via data- attribute
			var clickedGallery = clickedListItem.parentNode,
				childNodes = clickedListItem.parentNode.childNodes,
				numChildNodes = childNodes.length,
				nodeIndex = 0,
				index;

			for (var i = 0; i < numChildNodes; i++) {
				if (childNodes[i].nodeType !== 1) {
					continue;
				}

				if (childNodes[i] === clickedListItem) {
					index = nodeIndex;
					break;
				}
				nodeIndex++;
			}

			if (index >= 0) {
				// open PhotoSwipe if valid index found
				openPhotoSwipe(index, clickedGallery);
			}
			return false;
		};

		// parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function () {
			var hash = window.location.hash.substring(1),
				params = {};

			if (hash.length < 5) {
				return params;
			}

			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}

			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}

			return params;
		};

		var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				gallery,
				options,
				items;

			items = parseThumbnailElements(galleryElement);

			// define options (if needed)
			options = {
				// define gallery index (for URL)
				galleryUID: galleryElement.getAttribute('data-pswp-uid'),

				getThumbBoundsFn: function (index) {
					// See Options -> getThumbBoundsFn section of documentation for more info
					var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
						pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
						rect = thumbnail.getBoundingClientRect();

					return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
				}
			};

			// PhotoSwipe opened from URL
			if (fromURL) {
				if (options.galleryPIDs) {
					// parse real index when custom PIDs are used
					// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
					for (var j = 0; j < items.length; j++) {
						if (items[j].pid == index) {
							options.index = j;
							break;
						}
					}
				} else {
					// in URL indexes start from 1
					options.index = parseInt(index, 10) - 1;
				}
			} else {
				options.index = parseInt(index, 10);
			}

			// exit if index not found
			if (isNaN(options.index)) {
				return;
			}

			if (disableAnimation) {
				options.showAnimationDuration = 0;
			}

			// Pass data to PhotoSwipe and initialize it
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();
		};

		// loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll(gallerySelector);

		for (var i = 0, l = galleryElements.length; i < l; i++) {
			galleryElements[i].setAttribute('data-pswp-uid', i + 1);
			galleryElements[i].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if (hashData.pid && hashData.gid) {
			openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
		}
	}

	function initAccordion() {
		$('.accordion__head').on('click', function () {
			$(this).parents('.accordion__pannel').siblings().find('.accordion__body').slideUp(280);
			$(this).parents('.accordion__pannel').find('.accordion__body').slideToggle(280);
		});
	}

	function initOffersSelector() {
		if (!$('input[name="offer"]').length) {
			return;
		}

		$('input[name="offer"]').on('change', function () {
			$('input[name="input_29"]').val(this.value);
		});

		$doc.ready(function () {
			$('input[name="input_29"]').val($('input[name="offer"]:checked').val());
		});
	}

	function initOffersAnalytics() {
		if (!$('input[name="input_52"]').length) {
			return;
		}

		$doc.ready(function () {
			$('input[name="input_52"]').val(window.location.search.substr(1));
		});
	}

	function movePaymentStepTitle() {
		if (!$('.step-3').length || !$('.payment-selector').length) {
			return;
		}

		$doc.ready(function () {
			$('.step-3').insertBefore('.payment-selector');
		});
	}

	function initSaleSubmit() {
		$('.section--purchase-sale form .gfield_radio label').on('mouseup', function () {
			let selection = $(this).attr('for');
			let $selectionInput = $('#' + selection);
			$selectionInput.prop('checked', true);
			$(this).closest('form').submit();
		});
	}

	function initTimeCounter() {
		if (!document.getElementById('time-counter')) {
			return;
		}

		const elementContainer = document.getElementById('time-counter');
		const timeInSec = elementContainer.dataset.counter;
		const countDownDate = new Date().getTime() + timeInSec * 1000;

		var x = setInterval(function () {
			const now = new Date().getTime();
			const distance = countDownDate - now;

			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (seconds < 10) {
				seconds = '0' + seconds;
			}

			if (hours > 0) {
				elementContainer.innerHTML = hours + ' : ' + minutes + ' : ' + seconds;
			} else {
				elementContainer.innerHTML = minutes + ' : ' + seconds;
			}

			if (distance <= 0) {
				clearInterval(x);
				document.getElementById('time-counter').innerHTML = '';
			}
		}, 1000);
	}
})(jQuery);
